import { graphql, useStaticQuery } from "gatsby";
import {
  mapToSitemap as fhirMapToSitemap,
  outletId as FHIROutletId,
} from "../contentOutlets/fhir-api-reference";
import {
  mapToSitemap as DataModelAPIRedirectMapToSitemap,
  outletId as DataModelApiReferenceOutletId,
} from "../contentOutlets/data-model-api-reference-redirect";
import {
  mapToSitemap as PlatformApiMapToSitemap,
  outletId as PlatformApiOutletId,
} from "../contentOutlets/platform-api-reference";
import {
  mapToSitemap as WebhookMapToSitemap,
  outletId as WebhookOutletId,
} from "../contentOutlets/fhir-webhook-api-reference";
import { isNavigationEntry } from "../graphql-and-validation/sitemapConditionalValidation";
import { validateEntry } from "../graphql-and-validation/entry/validator";

import {
  createBreadCrumbDictionary,
  createLinksDictionary,
  createSideNavDataForAllPages,
  hydrateContentOutlets,
} from "./utils";
import { ContentOutlets } from "src/types";
import React from "react";

/**
 * BEWARE ALL YE WHO ENTER HERE:
 * PLEASE ONLY INCLUDE WHAT YOU NEED IN THIS QUERY.
 * THE OUTPUT LOADS ON THE INITIAL BUNDLE SO CAN BE REALLY EXPENSIVE IF LARGE QUERIES ARE PERFORMED!
 */
const siteMapQuery = graphql`
  query MasterSiteMap {
    contentfulNavigationSection(rootPage: { slug: { eq: "home" } }) {
      contentful_id
      __typename
      rootPage {
        ...Page
      }
      navigation {
        ...Page
        ...ContentOutlet
        ... on ContentfulNavigationSection {
          contentful_id
          __typename
          rootPage {
            ...Page
          }
          navigation {
            ...Page
            ...ContentOutlet
            ... on ContentfulNavigationSection {
              contentful_id
              __typename
              rootPage {
                ...Page
              }
              navigation {
                ...Page
                ...ContentOutlet
                ... on ContentfulNavigationSection {
                  contentful_id
                  __typename
                  rootPage {
                    ...Page
                  }
                  navigation {
                    ...Page
                    ...ContentOutlet
                    ... on ContentfulNavigationSection {
                      contentful_id
                      __typename
                      rootPage {
                        ...Page
                      }
                      navigation {
                        ...Page
                        ...ContentOutlet
                        ... on ContentfulNavigationSection {
                          contentful_id
                          __typename
                          rootPage {
                            ...Page
                          }
                          navigation {
                            ...Page
                            ...ContentOutlet
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
    allFhirResource {
      edges {
        node {
          id
          type
          interaction {
            code
          }
          operation {
            name
            path
          }
        }
      }
    }
    allPlatformApiPath {
      edges {
        node {
          id
          path
          operationId
          internal {
            type
          }
          tags
          summary
          verb
          operation
        }
      }
    }
    allPlatformApiResource {
      edges {
        node {
          id
          internal {
            type
          }
          name
        }
      }
    }
    allWebhookPath {
      edges {
        node {
          id
          internal {
            type
          }
          trigger
          topic
        }
      }
    }
    allWebhookTopic {
      edges {
        node {
          id
          internal {
            type
          }
          openAPI
        }
      }
    }
  }
`;

export const useSitemap = () => {
  const [isOpen, setIsOpen] = React.useState(true);
  const queryResults: Queries.MasterSiteMapQuery = useStaticQuery(siteMapQuery);

  const validatedTopLevelNavigation = isNavigationEntry(
    validateEntry(queryResults.contentfulNavigationSection)
  );
  const fhirData = queryResults.allFhirResource.edges.map(({ node }) => node);
  const platformApiContent = queryResults.allPlatformApiPath.edges.map(
    ({ node }) => node
  );
  const platformApiTags = queryResults.allPlatformApiResource.edges.map(
    ({ node }) => node
  );
  const webhookContent = queryResults.allWebhookPath.edges.map(
    ({ node }) => node
  );
  const webhookTopics = queryResults.allWebhookTopic.edges.map(
    ({ node }) => node
  );

  const contentOutlets: ContentOutlets = {
    [FHIROutletId]: () => fhirMapToSitemap(fhirData),
    [WebhookOutletId]: () => WebhookMapToSitemap(webhookContent, webhookTopics),
    [PlatformApiOutletId]: () =>
      PlatformApiMapToSitemap(platformApiTags, platformApiContent),
    [DataModelApiReferenceOutletId]: () => DataModelAPIRedirectMapToSitemap(),
  };

  if (validatedTopLevelNavigation) {
    // mutate the records in initialTopLevelData with the contentOutlet content
    hydrateContentOutlets({ ...validatedTopLevelNavigation }, contentOutlets);
  }

  const topLevelDataWithContentHydratedOutlets = isNavigationEntry(
    validatedTopLevelNavigation
  );

  if (!topLevelDataWithContentHydratedOutlets) {
    throw new Error(
      "There was an issue hydrating content outlets and the site cannot be built."
    );
  }

  const sitemapLinkDictionary = createLinksDictionary(
    topLevelDataWithContentHydratedOutlets
  );

  const breadCrumbDictionary = createBreadCrumbDictionary(
    topLevelDataWithContentHydratedOutlets,
    sitemapLinkDictionary
  );

  const sideNavStatesDictionary = createSideNavDataForAllPages(
    topLevelDataWithContentHydratedOutlets
  );

  return {
    sitemapLinkDictionary,
    breadCrumbDictionary,
    sideNavStatesDictionary,
    isOpenState: {
      isOpen,
      setIsOpen,
    },
  };
};

import { Actions } from "gatsby";
import { ValidatedPageWithoutContent } from "src/graphql-and-validation/page/validator";

export const outletId = "data-model-api-reference";
const baseSlug = "data-model-api-reference";

/**
 * @param FHIRResources The FHIR resources pulled from the GraphQL query
 * @returns
 */
export const mapToSitemap = (): ValidatedPageWithoutContent => {
  return {
    contentful_id: `data-model-api-reference-redirect`,
    slug: baseSlug,
    title: "Data Model API",
    __typename: "ContentfulPage",
  } as ValidatedPageWithoutContent;
};

export const createPages = (
  path: string,
  createPageFn: Actions["createPage"],
  pathFn
) => {
  createPageFn({
    path: `${path}/${baseSlug}`,
    context: {
      id: `data-model-api-reference-redirect`,
    },
    component: pathFn.resolve(
      "./src/components/data-model-api-reference-redirect-page.tsx"
    ),
  });
};
